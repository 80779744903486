
    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: local('Switzer Regular'), url('Switzer-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: local('Switzer Italic'), url('Switzer-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 100;
    font-display: swap;
    src: local('Switzer Thin'), url('Switzer-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 100;
    font-display: swap;
    src: local('Switzer Thin Italic'), url('Switzer-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 200;
    font-display: swap;
    src: local('Switzer Extralight'), url('Switzer-Extralight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 200;
    font-display: swap;
    src: local('Switzer Extralight Italic'), url('Switzer-ExtralightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: local('Switzer Light'), url('Switzer-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 300;
    font-display: swap;
    src: local('Switzer Light Italic'), url('Switzer-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: local('Switzer Medium'), url('Switzer-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 500;
    font-display: swap;
    src: local('Switzer Medium Italic'), url('Switzer-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 600;
    src: local('Switzer Semibold'), url('Switzer-Semibold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 600;
    src: local('Switzer Semibold Italic'), url('Switzer-SemiboldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 700;
    src: local('Switzer Bold'), url('Switzer-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 700;
    src: local('Switzer Bold Italic'), url('Switzer-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 800;
    src: local('Switzer Extrabold'), url('Switzer-Extrabold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 800;
    src: local('Switzer Extrabold Italic'), url('Switzer-ExtraboldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  normal;
    font-weight: 900;
    src: local('Switzer Black'), url('Switzer-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Switzer';
    font-style:  italic;
    font-weight: 900;
    src: local('Switzer Black Italic'), url('Switzer-BlackItalic.woff') format('woff');
    }